function SoHoBlue() {
    return (
      <div>
        <h1 className="text-[#F5F5F5] leading-none text-center text-[92px] md:text-[250px] xl:text-[400px] relative mt-8">
          Ai GUY
        </h1>
      </div>
    );
  }
  
  export default SoHoBlue;
  