function SoHoTan() {
  return (
    <div>
      <h1 className="text-[#0C2152] leading-none text-center text-[92px] md:text-[250px] xl:text-[400px] relative mt-8">
        Ai GUY
      </h1>
    </div>
  );
}

export default SoHoTan;
